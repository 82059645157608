import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'coscl-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    translations = {
        cableOsCentral: this.translateService.instant('CABLE_OS_CENTRAL'),
    };

    constructor(private translateService: TranslateService) {
    }
}
