import { Component } from '@angular/core';

@Component({
    selector: 'coscl-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

}
