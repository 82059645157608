import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  FooterComponent,
  HeaderComponent,
  CardComponent
} from './components';

const sharedModules = [
  RouterModule,
  CommonModule
];

const sharedComponents = [
  HeaderComponent,
  FooterComponent,
  CardComponent
];

const sharedDirectives = [];

const sharedPipes = [];

@NgModule({
  imports: [
    ...sharedModules
  ],

  declarations: [
    ...sharedComponents,
    ...sharedDirectives,
    ...sharedPipes,
    CardComponent
  ],

  exports: [
    ...sharedModules,
    ...sharedComponents,
    ...sharedDirectives,
    ...sharedPipes
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
