import {
  Component,
  OnInit
} from '@angular/core';
import { SettingsService } from '../shared/services';
import { IMAGES_MAP, DEPRECATED_IMAGES_MAP, DEPRECATED_TEXT_MAP } from '../shared/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'coscl-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  pluginsSettings: any[];
  translations = {
    welcome: this.translateService.instant('WELCOME'),
    aboutHarmonicCableOs: this.translateService.instant('CABLE_OS_INFO_ABOUT_INFO'),
  };

  constructor(private settingsService: SettingsService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.getPluginsSettings();
  }

  trackByFn(index) {
    return index;
  }

  isDeprecated(name: string) {
    if (DEPRECATED_IMAGES_MAP[name]) {
      return true;
    }
  }

  getImageDeprecated(name: string) {
    if (DEPRECATED_IMAGES_MAP[name]) {
      return DEPRECATED_IMAGES_MAP[name];
    } else {
      return 'plugin.svg';
    }
  }

  getDeprecatedText(name: string) {
    if (DEPRECATED_TEXT_MAP[name]) {
      return DEPRECATED_TEXT_MAP[name];
    }
  }

  getImage(name: string) {
    if (IMAGES_MAP[name]) {
      return IMAGES_MAP[name];
    }
  }

  openPlugin(link: string) {
    if (link) {
      window.location.replace(link);
    }
  }

  private getPluginsSettings() {
    this.settingsService.getJsonSettings().subscribe(settings => {
      this.pluginsSettings = Object.entries(settings).map(([key, value]) => ({key, value}));
    });
  }

}
