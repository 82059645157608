import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_TRANSLATION_EN } from './shared/i18n';

@Component({
  selector: 'coscl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private translateService: TranslateService) {
    this.initTranslation();
  }

  /**
   * Init translations and configure translation service
   */
  private initTranslation(): void {
    this.translateService.addLangs(['en']);
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
    this.translateService.setTranslation('en', APP_TRANSLATION_EN, true);
  }
}
