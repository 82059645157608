import { Injectable } from '@angular/core';
import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams
} from '@ngx-translate/core';
import { NotTranslatedService } from './not-translated.service';

@Injectable()
export class CustomMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        this.nts.notTranslated(params.key);
        return '[MISSING]' + params.key;
    }

    constructor(private nts: NotTranslatedService) {}
}
