import {
    ChangeDetectionStrategy,
    Component,
    Input
} from '@angular/core';
import { COLORS_NAMES } from '../../models';

@Component({
    selector: 'coscl-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {
    @Input() name: string;
    @Input() color: string;
    @Input() active = false;
    colors = COLORS_NAMES;
}
