import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 *  Service for getting plugins settings
 */
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) {
  }

  public getJsonSettings(): Observable<any> {
    return this.http.get(`assets/plugins.json`);
  }
}
