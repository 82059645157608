export const COLORS = {
    HARMONIC_LOGO_BLUE: '#00355F',
    HARMONIC_DARK_BLUE: '#005695',
    HARMONIC_LIGHT_BLUE: '#24B1D9',
    HARMONIC_TURQUOISE: '#1AC7B7',
    HARMONIC_LIGHT_GREY: '#b5b5b5',
    HARMONIC_DARK_GREY: '#414042',
    HARMONIC_MAGENTA: '#C50041',
    HARMONIC_ORANGE: '#D72515',
    ORANGE: '#ff922d',
    SALAD: '#92f9af'
};

export enum COLORS_NAMES {
    BLUE =  'blue',
    LIGHT_BLUE = 'light-blue',
    HARMONIC_LIGHT_BLUE = 'harmonic-light-blue',
    BLUE_GREEN = 'blue-green',
    LIGHT_GREY = 'light-grey',
    GREEN = 'green',
    GREY = 'grey',
    DARK_GREY = 'dark-grey',
    RED = 'red',
    ORANGE = 'orange',
    YELLOW = 'yellow',
    WHITE = 'white'
}
