export const IMAGES_MAP = {
  analytics: 'cableos_analytics_logo.png',
  sonar: 'cableos_sonar_logo.png',
  portal: 'cableos_portal_logo.png',
  jenkins: 'jenkins_logo.png',
  argocd: 'argocd_logo.png',
  elastic: 'elastic_logo.png',
  vault: 'vault_logo.png',
  teleport: 'teleport_logo.png'
}

export const DEPRECATED_IMAGES_MAP = {
  api: 'api.svg',
  logs: 'kibana.svg',
  dashboards: 'grafana.svg',
  alerts: 'alerts.svg',
  promui: 'prometheus.svg'
}

export const DEPRECATED_TEXT_MAP = {
  api: 'Timeseries DB API',
  logs: 'Logs',
  dashboards: 'cOS Analytics',
  alerts: 'AlertManager',
  promui: 'Prometheus UI'
}
